import React from 'react'
import 'twin.macro'
import { Link } from '../../utils/general';

export default function ServicesPw() {
    return (
        <div tw="py-12 bg-white">
            <div tw="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div tw="lg:text-center">
                <h2 tw="text-base text-secondary-900 font-semibold tracking-wide uppercase">OUR SERVICES</h2>
                <p tw="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl uppercase">
                what we do best
                </p>
                </div>

                <div tw="mt-10">
                <dl tw="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                    <div tw="flex">
                    <Link to="pages/pagespeed/" tw="flex hover:no-underline">
                    <div tw="flex-shrink-0">
                        <div tw="flex items-center justify-center h-12 w-12 rounded-md bg-secondary-900 text-white">
                        <svg tw="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                        </svg>
                        </div>
                    </div>
                    <div tw="ml-4">
                        <dt tw="text-lg leading-6 font-medium text-gray-900">
                        Pagespeed
                        </dt>
                        <dd tw="mt-2 text-base text-gray-500">
                        A faster website equals more conversions and isn’t that what we’re all looking for?
                        </dd>
                    </div>
                    </Link>
                    </div>

                    <div tw="flex">
                    <Link to="pages/seo/" tw="flex hover:no-underline">
                    <div tw="flex-shrink-0">
                        <div tw="flex items-center justify-center h-12 w-12 rounded-md bg-secondary-900 text-white">
                        <svg tw="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                        </svg>
                        </div>
                    </div>
                    <div tw="ml-4">
                        <dt tw="text-lg leading-6 font-medium text-gray-900">
                        SEO
                        </dt>
                        <dd tw="mt-2 text-base text-gray-500">
                        Search engine optimization, organic rankings, suppression.
                        </dd>
                    </div>
                    </Link>
                    </div>

                    <div tw="flex">
                    <Link to="pages/digital-design/" tw="flex hover:no-underline">
                    <div tw="flex-shrink-0">
                        <div tw="flex items-center justify-center h-12 w-12 rounded-md bg-secondary-900 text-white">
                        <svg tw="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                        </svg>
                        </div>
                    </div>
                    <div tw="ml-4">
                        <dt tw="text-lg leading-6 font-medium text-gray-900">
                        Digital Design
                        </dt>
                        <dd tw="mt-2 text-base text-gray-500">
                        Finding optimal balance in good looks and functionality to promote customer interaction. 
                        </dd>
                    </div>
                    </Link>
                    </div>

                    <div tw="flex">
                    <Link to="pages/data-analytics/" tw="flex hover:no-underline">
                    <div tw="flex-shrink-0">
                        <div tw="flex items-center justify-center h-12 w-12 rounded-md bg-secondary-900 text-white">
                        <svg tw="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                        </svg>
                        </div>
                    </div>
                    <div tw="ml-4">
                        <dt tw="text-lg leading-6 font-medium text-gray-900">
                        Data & Analytics
                        </dt>
                        <dd tw="mt-2 text-base text-gray-500">
                        The more we know about and understand your customer, the better we can appeal and connect with them.
                        </dd>
                    </div>
                    </Link>
                    </div>

                    <div tw="flex">
                    <Link to="pages/affiliate-marketing/" tw="flex hover:no-underline">
                    <div tw="flex-shrink-0">
                        <div tw="flex items-center justify-center h-12 w-12 rounded-md bg-secondary-900 text-white">
                        <svg tw="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                        </svg>
                        </div>
                    </div>
                    <div tw="ml-4">
                        <dt tw="text-lg leading-6 font-medium text-gray-900">
                        Affiliate Marketing
                        </dt>
                        <dd tw="mt-2 text-base text-gray-500">
                        Affiliate marketing is performance-based and so you only pay for what converts.
                        </dd>
                    </div>
                    </Link>
                    </div>

                    <div tw="flex">
                    <Link to="pages/customizations/" tw="flex hover:no-underline">
                    <div tw="flex-shrink-0">
                        <div tw="flex items-center justify-center h-12 w-12 rounded-md bg-secondary-900 text-white">
                        <svg tw="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                        </svg>
                        </div>
                    </div>
                    <div tw="ml-4">
                        <dt tw="text-lg leading-6 font-medium text-gray-900">
                        Customizations
                        </dt>
                        <dd tw="mt-2 text-base text-gray-500">
                        From custom navigation, pages, product pages, carts, to completely custom websites, we can help.
                        </dd>
                    </div>
                    </Link>

                    </div>


                </dl>
                </div>
            </div>
            </div>

    )
}
